import React from 'react';
import { Helmet } from 'react-helmet';
import data from '../data/data';

function Facility() {
  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{data.facility.meta.title}</title>
          <meta name="keywords" content={data.facility.meta.keywords} />
          <meta name="description" content={data.facility.meta.description} />
        </Helmet>
        <br />
        <h1   style={{ marginTop: "20px" }}  data-aos="fade-down">
          {data.facility.main_title}
        </h1>
        <p className='mt-3 para-align facility-para'  data-aos="fade-down">
          {data.facility.main_title_paragraph}
        </p>
        <br />
        <br />
          <img className='facility-image' src={data.facility.image} alt="insulatorimage"  data-aos="fade-down"/>
            <div data-aos="fade-down">
              <h3>
                {data.facility.title}
              </h3>
              <h4>
                {data.facility.subtitle1}
              </h4>
              <p className=' para-align facility-para'>
                {data.facility.paragraph1}
              </p>
              <h4 > 
                {data.facility.subtitle2}
              </h4>
              <p className='para-align facility-para'>
                {data.facility.paragraph2}
              </p>
              <h4 >
                  {data.facility.subtitle3}
              </h4>
              <p className='para-align facility-para'>
                  {data.facility.paragraph3}
              </p>
              <h4>
                {data.facility.subtitle4}
              </h4>
              <p className='para-align facility-para'>
                {data.facility.paragraph4}
              </p>
              <h4>
                {data.facility.subtitle5}
              </h4>
              <p className='para-align facility-para'>
                {data.facility.paragraph5}
              </p>
            </div>
      </div>
    </>)
}

export default Facility
