import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import { Helmet } from 'react-helmet';
// import {  Text, View, StyleSheet } from '@react-pdf/renderer';
import '../App.css';
// import Skeleton, { SkeletonTheme }  from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'
import data from '../data/data';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ProductCard(props) {
    const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const onButtonClick = () => {
        fetch(props.pdf_file).then(response => {

            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = props.title ;
                alink.click();
            })
        })
    }

    return (
        <div className='mt-5 container'>    
            <div className="product-pdf"  data-aos="fade-right">
                <div>      
                    <Button className='download-button' onClick={onButtonClick} style={{float:"right"}}>
                            <abbr title='Download PDF'>
                                <i class="fa-solid fa-cloud-arrow-down"></i>
                            </abbr>
                    </Button>
                    <h1>{props.title} </h1>
                </div>
                <div>                 
                    <Document id={props.id} style={{width : '300px'}} file={props.pdf_file} onLoadSuccess={onDocumentLoadSuccess} renderMode="svg">
                        {
                            Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map(page => <Page scale={1.5} pageNumber={page}/>)                           
                        }
                    </Document>                   
                    <br/> 
                </div>
            </div>
            <div  data-aos="fade-left">

                <h3 className='mt-5'> { data.product_Description.title }  </h3>
                <br/>
                <h4>{ data.product_Description.subtitle1 }  </h4>
                <p className='content-p-animation para-align facility-para'> These materials have the specific characteristics needed for
                    { data.product_Description.paragraph1 }
                </p>
                <h4>{ data.product_Description.subtitle2 } </h4>
                <p className='content-p-animation para-align facility-para'>EPOXY are used in a variety of applications in the
                { data.product_Description.paragraph2 }
                </p>
            </div>
        </div>
    )
}

export default ProductCard;

