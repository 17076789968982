import React from 'react';
import {useEffect,useState} from 'react';
import Button from 'react-bootstrap/esm/Button';

function FloatToTop() {
    const[backtotop, setBacktoTop]=useState(false);
    useEffect( () =>{
        window.addEventListener('scroll',()=>{
            if(window.scroll > 100){
                setBacktoTop(true)
            }
            else{
                setBacktoTop(false)
            }
        })
    },[])

    const movetoTop= () =>{
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
        
    }
  return (
    <div style={{float:"right"}}>{
        FloatToTop &&( <Button  onClick={movetoTop}>
            <i class="fa-sharp fa-solid fa-arrow-up-from-bracket" style={{fontSize:"30px",color:"rgb(155, 35, 14)"}}></i>
        </Button>)}
    

    </div>
  )
}

export default FloatToTop
