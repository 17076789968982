import React from 'react';
import Logo from '../data/images/logo.webp';
import { Link } from "react-router-dom";
import '../App.css';
import data from '../data/data';

function Footer() {
    return (
        <>
        <hr className='mt-3 hr-style-one'  />
        <div className='footer row  footer-logo-padding'  style={{ margin: "auto", float: "none" }} data-aos="fade-down">
            
            <div className='col-lg-4 ' >
                <Link to='/'>
                    <img className='footer-log' src={Logo} alt="logoimage" />
                </Link>             
                <br />
                <p className='para-align'>
                    {data.footer.quote}
                </p>
                <span style={{ fontSize: "40px", color: "rgb(155, 35, 14)" }}>
                        <a href='https://twitter.com/SunshineEngine2' className='media-link'>
                        <i class="fa-brands fa-twitter"></i> {''}
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=100085896574961' className='media-link'>
                        <i class="fa-brands fa-facebook"></i>{' '}
                        </a>
                        <a href='https://www.linkedin.com/in/sunshine-engineering-6a8153250/' className='media-link'>
                        <i class="fa-brands fa-linkedin"></i>{' '}
                        </a>
                    </span>
                
            </div>
            <div className='col-lg-3' style={{ paddingTop: "30px" }}>
                <div>
                    <Link className='footer-link footer-link-center' to="/">
                        Home
                    </Link>
                    <br />
                    <Link className='footer-link' to="/about">
                        About Us
                    </Link>
                    <br />
                    <Link className='footer-link' to="/products">
                        Product
                    </Link>
                    <br />
                    <Link className='footer-link' to="/facility">
                        Facility
                    </Link>
                    <br />
                    <Link className='footer-link' to="/contact">
                        Contact Us
                    </Link>
                    <br/>
                    <br/>
                    <h5>
                        Visit us
                    </h5>
                    <a className='footer-link' href="https://sunshineengineering.co/">
                        www.sunshineengineering.co
                    </a>
                    <br />
                    <br />
                   
                </div>
            </div>
            <div className='col-lg-5' style={{ paddingTop: "30px" }}>
                <p>
                    <i class="fa-solid fa-building footer-icon nav-icon"></i>
                    {data.footer.adress1}
                </p>
                <p>
                    <i class="fa-solid fa-industry footer-icon nav-icon"></i> 
                    {data.footer.adress2}
                </p>
                <p style={{paddingLeft:"25px"}}>{data.footer.gstin}</p>
                <p>
                    <i class="fa-solid fa-envelope footer-icon nav-icon"></i>
                    <Link className='footer-link' to="#"  
                    onClick={(e) => {
                    window.location.href="mailto:sunshineengineering23@gmail.com";
                    e.preventDefault();
                    }}>
                        {data.footer.email2}
                    </Link>
                    <br />
                    <i class="fa-solid fa-envelope footer-icon nav-icon"></i>
                    <Link className='footer-link' to="#"
                        onClick={(e) => {
                        window.location.href="mailto:sales@sunshineengineering.co";
                        e.preventDefault();
                    }}>                  
                        {data.footer.email1}
                    </Link>
                    <br />
                    <br />
                   <span>
                   <i className="fa-solid fa-mobile-screen footer-icon nav-icon"></i>
                     {data.footer.mobile_no2}
                   </span>
                    <br />
                    
                    <div>
                        <a  href="https://api.whatsapp.com/send?phone=+91 9356202821&text=This%20is%20test%20Message" className='footer-link'>
                        <i class="fa-brands fa-whatsapp footer-icon"></i> {data.footer.mobile_no1}
                        </a>
                    </div>
                </p>
                
            </div>
            <div  style={{display:"flex"}}>
                <p>
                    <a className='footer-link' href="https://sunshineengineering.co/">
                        © Sunshine Engineering ·
                    </a>
                </p>
                <p>
                    <a className='footer-link' href="#privacy">
                       {' '} Privacy ·{' '}
                    </a> 
                </p>
                <p> 
                    <a className='footer-link' href="#terms"> 
                     Terms 
                    </a> 
                </p>
            </div>
            <div >              
                <p className='foter-float'>
                    Made by  {' '}  
                     <a className='footer-link' href="https://www.anyonecancode.in/">
                         SMK Solutions
                    </a>
                </p>
            </div>
        </div>
       
        
   </> )
}

export default Footer
