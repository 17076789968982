import React from 'react';
import data from '../data/data';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function ProductTile() {
  return (
    <div style={{ paddingTop: "40px" }}>
      <div className='row container card-row' style={{ margin: "auto", float: "none" }}>
      {/* <h4 className=' mb-3'>PRODUCTS</h4>
      <br/> */}
        {
          data.product_docs.map(product =>
            <div className='col-md-6 col-lg-4 col-12 card-align-2-row' >
              <Card className='product-hover pcard-style' border="dark" style={{minHeight:"398px"}}>
                <Card.Img variant="top" src={product.image} style={{ height: "200px" }} />
                <Card.Body>
                  <Card.Title><h3>{product.title}</h3></Card.Title>
                  <Card.Text>
                    {product.text}
                  </Card.Text>
                  <Link to={`/bush/${product.id}`} style={{ textDecoration: "none", color: "rgb(155, 35, 14)" }}>Know More...</Link>
                </Card.Body>
              </Card>
              <br/>
            </div>          
          )        
        }        
      </div> 
          
    </div>
  )
}

export default ProductTile
