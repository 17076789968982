const data = {
    home : 
    {
        title: "SUNSHINE ENGINEERING",
        subtitle: "",
        description: <> Sunshine Engineering is manufacturers of various types of Epoxy Bushings & Insulators.<br /><br />
        Epoxy resins are excellent electrical insulators and protect electrical components from short circuiting,
        dust and moisture. EPOXY are used in a variety of applications in the medium and high voltage industry.
        Besides the classical medium voltage from 12 to 36 kV and high voltage up 1000 kV other industries, like the
        automotive, high-speed train and motor stator applications show a rapid
        growth and acceptance in the market.<br/><br />

        <b>SE adheres to all latest national and international standards. A dedicated quality management team ensures
            overall quality of product.
            It is sister company of Kristorr Vacuum Equipment's pvt ltd.(formerly known as Sai guru Engineers).</b>
        <br /><br />
        Sunshine Engineering is committed to quality product which is based on belief that the best possible products
        should be provided to needy customers without discriminations.<br /><br />
        Our ultimate objective is to provide each and every customer a “zero defect product”. In order to achieve this,
        SE  is committed to establishing necessary facilities to produce quality products of international standards,
        conduct research programs in various fields of development and ensure translation of research findings into
        improvements in the products.<br /><br />
        Performance is evaluated on a number of factors such as job knowledge, competence, initiative and imagination,
        inter-personal relationships and discipline. </>,
        image: "../data/images/sunshine.webp",
        subImage1: "../data/images/ezgif.com-gif-maker.gif",
        subImage2:"../data/images/transformer-image.jpg",
        quote: "Sunshine Engineering is manufacturers of various types of Epoxy Bushings  & Insulators",
        meta : {
            title: 'Sunshine Engineering | Home',
            keywords: 'manufacturers of various Bushings, manufacturers of various Insulators, Insulators,Bushings, Epoxy resins insulator,quality insulators,Zero defect insulators,Zero defect bushings',
            description: 'Sunshine Engineering is committed to quality product which is based on belief that the best possible products should be provided to needy customers without discriminations.Our ultimate objective is to provide each and every customer a “zero defect product”.'
        }
    },
    media : [
        {
            id: 1,
            name: "RMU Bush",
            image : '../data/images/rmu-bush.jpg',
            type: "tile",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        },
        {             
            id: 2,
            name: "Breaker Bush",
            image : '../data/images/breaker-bush.jpg',
            type: "tile",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        },
        {             
            id: 3,
            name: "Spout Bushing",
            image : '../data/images/spout-bushing.jpg',
            type: "tile",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        },
        {             
            id: 4,
            name: "AboutImage",
            image : '../data/images/aboutus-image.jpg',
            type: "main",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        },
        {
            id: 5,
            name: "HomeImage",
            image : '../data/images/homeImage.jpg',
            type: "home",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        },
        {
            id: 6,
            name: "TransformerOne",
            image : '../data/images/carousel1-transformer.jpg',
            type: "caraousel",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        },
        {
            id: 7,
            name: "TransformerTwo",
            image : '../data/images/carousel2-transformer.webp',
            type: "caraousel",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        },
        {
            id: 8,
            name: "TransformerThree",
            image : '../data/images/carousel3-transformer.webp',
            type: "caraousel",
            title: 'Sunshine Engineering',
            subtitle: 'Make the difference in Engineering ...'
        }
    ],
    products:{
        meta : {
            title: 'Sunshine Engineering | Products',
            keywords: '3.3 KV class insulator,6.6 KV class insulator,11 KV class insulator,33 KV class insulator,66 KV class insulator,1.1 kv bush insulator,1.1 kv 4000 Amps bush,1.1 kv 5000 Amps bush,1.1 630 kv bush,1.1 kv/ 3.6 1000Amps bush,1.1 kv/ 3.6 2000Amps bush, bushings, different bushes',
            description: 'We can also supply non Epoxy Bushings as per your drawing'
        }
    },
    product_docs : [
        {
            id: "1",
            doc: "../data/pdf/SE-EB01-003.PDF-250-400-500-630 Amp.PDF",
            image: "../data/images/1.1-250-amp-bush.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "1.1 KV 250Amp",
            link: "/bush",
            type: "bush",
            // meta : {
            //     title: 'Sunshine Engineering | 1.1 KV Bush',
            //     keywords: '1.1 kv bush, bushings, different bushes',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "2",
            doc: "../data/pdf/SE_ EB01_002(1)_630_100Amp.PDF",
            image: "../data/images/1.1-630-amp-bush.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "1.1 KV 630 Amp",
            link: "/bush",
            type: "bush",
            // meta : {
            //     title: 'Sunshine Engineering | 1.1 kv 630 Amps Bushing',
            //     keywords: '1.1 630 kv bush, bushings, different bushes',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "3",
            doc: "../data/pdf/SE-EB01-001.PDF-1250-2000-3150 Amp.PDF",
            image: "../data/images/1.1-1000-amp-bush.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "1.1 KV / 3.6 KV 1000 Amps",
            link: "/bush",
            type: "bush",
            // meta : {
            //     title: 'Sunshine Engineering | 1.1 kv/3.6 kv 1000 Amps Bushing',
            //     keywords: '1.1 / 3.6 kv 1000Amps bush, bushings, different bushes',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "4",
            doc: "../data/pdf/SE-EB01-004 (1).PDF-4000-5000 Amp.PDF",
            image: "../data/images/1.1-2000-amp-bush.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "1.1 KV / 3.6 KV 2000 Amps",
            link: "/bush",
            type: "bush",
            // meta : {
            //     title: 'Sunshine Engineering | 1.1 kv/3.6 kv 2000 Amps Bushing',
            //     keywords: '1.1 kv/3.6 kv 200Amps bush, bushings, different bushes',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "5",          
            doc: "../data/pdf/SE-EB01-007.PDF-5000 Amp.PDF",
            image: "../data/images/1.1-3000-amp-bush.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "1.1 KV 4000 Amps",
            link: "/bush",
            type: "bush",
            // meta : {
            //     title: 'Sunshine Engineering | 1.1 kv 4000 Amps Bushing',
            //     keywords: '1.1 kv 4000Apms bush, bushings, different bushes',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "6",
            doc: "../data/pdf/SE-EB01-006.PDF-4000 Amp.PDF",
            image: "../data/images/1.1-4000-amp-bush.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "1.1 KV 5000 Amps",
            link: "/bush",
            type: "bush",
            // meta : {
            //     title: 'Sunshine Engineering | 1.1 kv 5000 Amps Bushing',
            //     keywords: '1.1 kv 5000Amps bush, bushings, different bushes',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        // {
        //     id: "7",
            
        //     doc: "../data/pdf/SE-EI11-001.PDF-11KV - 61.PDF",
        //     image: "../data/images/1.1-kv-insulator.webp",
        //     text: "We can also supply non Epoxy Bushings as per your drawing",
        //     title: "1.1 KV class",
        //     link: "/insulator",
        //     type: "insulator"
        // },
        {
            id: "8",
            doc: "../data/pdf/SE-EI03-001.PDF-3.3 KV.PDF",
            image: "../data/images/3.3-kv-insulator.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "3.3 KV class",
            link: "/insulator",
            type: "insulator",
            // meta : {
            //     title: 'Sunshine Engineering | 3.3 Kv class Insulator',
            //     keywords: '3.3 kv class insulator, insulators, different insulators',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "9",
            doc: "../data/pdf/SE-EI06-001.PDF- 6.6 KV.PDF",
            image: "../data/images/6.6-kv-insulator.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "6.6 KV class",
            link: "/insulator",
            type: "insulator",
            // meta : {
            //     title: 'Sunshine Engineering | 6.6 Kv class Insulator',
            //     keywords: '6.6 kv class insulator, insulators, different insulators',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "10",
            doc: "../data/pdf/SE-EI11-003.PDF-11KV 77.PDF",
            image: "../data/images/11-kv-insulator.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "11 KV class",
            link: "/insulator",
            type: "insulator",
            // meta : {
            //     title: 'Sunshine Engineering | 11Kv class Insulator',
            //     keywords: '11 kv class insulator, insulators, different insulators',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "11",
            doc: "../data/pdf/SE-EI22-001.PDF-22KV.PDF",
            image: "../data/images/33-kv-insulator.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "22 KV class",
            link: "/insulator",
            type: "insulator",
            // meta : {
            //     title: 'Sunshine Engineering | 22kv class Insulator',
            //     keywords: '22 kv class insulator, insulators, different insulators',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        },
        {
            id: "12",
            doc: "../data/pdf/SE-EI33-001.PDF-33KV.PDF",
            image: "../data/images/66-kv-insulator.webp",
            text: "We can also supply non Epoxy Bushings as per your drawing",
            title: "33 KV class",
            link: "/insulator",
            type: "insulator",
            // meta : {
            //     title: 'Sunshine Engineering | home',
            //     keywords: '33 kv class insulator, insulators, different insulators',
            //     description: 'We can also supply non Epoxy Bushings as per your drawing'
            // }
        }
    ],
    facility : 
        {
            main_title :"APG CLAMPING MACHINES",
            main_title_paragraph : <>Our clamping machines for EPOXY systems is suitable for production
            ranges of Insulators 1.1 Kv to 33 KV , Epoxy Bushing 1.1 Kv 250 Amps to
            5000 Amps,Epoxy Bushing 3.6 kv 1000 amps to 5000 amps & Epoxy Bushings 12 Kv, 250 Amps to 1000 Amps
            Both machines have been designed with a wide opening of the mold plates
            and an easy "walk-in" design. The machines are fully accessible from both
            sides. For all types and sizes, heating plates are available. Self closing
            nozzles, The clamping mechanism is pressing the mould according to the
            method of pulling the mould together with hydraulic cylinders. These types
            are most advanced and keep the focus on a design of precisely closing and opening the mould including
            all pullers.</>,

            image:"../data/images/apg-clamping.webp",
            title:"Epoxy Processing ",

            subtitle1:"Strength",
            paragraph1:<>Creating high dielectric properties, mechanical strength as well as insulating
            properties for electrical applications. For over 50 years EPOXY and PUR
            have been the standard materials for achieving well engineered insulation
            characteristics in reference to heavy electrical usage.</>,
            
            subtitle2:"Applications",
            paragraph2:<>Medium and high voltage industry. Besides the classical medium voltage
            from 12 to 36 kV and high voltage up 1000 kV other industries, like the
            automotive, high-speed train and motor stator applications show a rapid
            growth and acceptance in the market. The advantage lies in the fact that
            that in many cases, components can be built smaller, compared to conventional insulation technologies.
            Sunshine Engineering Specializes in designing and manufacturing of complete assembled products for electric 
            panels, switchgears, circuit breakers,& bushings using epoxy
            And medium and high voltage industry. Besides the classical medium
            voltage from 12 to 36 kV and high voltage up 1000 kV other industries, like the automotive,
            high-speed train and motor stator applications show a rapid growth and acceptance in the market. 
            The advantage lies in the fact that that in many cases, components can be built smaller,
            compared to conventional insulation technologies.</>,

            subtitle3:"Production Capacity",
            paragraph3:<>
            <ul style={{listStyleType:"square"}}>
                <li>
                    5000 sq ft production space.
                </li>
                <li>
                    5 High Power vacuum with material- mixing capability.
                </li>
                <li>
                    Production capability of 5 ton of material per day.
                </li>
                <li>
                    Single cavity and multi cavity molds operated by clamping units.
                </li>
                <li>
                    10 Automated pressure gelation (APG) machines.
                </li>
                <li>
                    Hot air oven.
                </li>
                <li>
                    Expert workforce , supervisors, Quality checking inspectors.
                </li>
            </ul>
             </>,
            adress:"Registered address",
            subtitle4:"Testing and Quality",
            paragraph4:<>Assured products that have undergone our state of the art testing equipments.
                <ul style={{listStyleType:"square"}}>
                    <li>
                        Precision dimension testing
                    </li>
                    <li>
                        Bending and hydraulic testing
                    </li>
                    <li>
                        Medium and High voltage electric testing
                    </li>
                    <li>
                        X-ray testing
                    </li>
                </ul>
            </>,
            subtitle5:"Material",
            paragraph5:<>High quality Epoxy resin for indoor and outdoor application, these materials have
             specific characteristics needed for creating high dielectric properties,mechanical strength as 
             well as insulating properties for electrical applications.<br/>
             The product has been tested to attain acceptance by ERDA.<br/>
             <ul style={{listStyleType:"square"}}>
                <li>
                Various electrical , mechanical and physical tests.
                </li>
             </ul>
            We are under the process of CPRI….
             </>,
              meta : {
                title: 'Sunshine Engineering | Facility',
                keywords: 'APG Clamping Machine,Expert workforce,clamping mechanism is pressing,heating plates,dielectric, supervisors, Quality checking inspectors,Hot air oven, APG Clamping Machine,facility from manufacturer,Assure product',
                description: "The machines are fully accessible from both sides.5000 sq ft production space.10 Automated pressure gelation (APG) machines.Expert workforce , supervisors, Quality checking inspectors."
            }
        },
    product_Description : 
        {
            title:"EPOXY PROCESSING ",

            subtitle1:"STRENGTH",
            paragraph1:<>Creating high dielectric properties, mechanical strength as well as insulating
            properties for electrical applications. For over 50 years EPOXY and PUR
            have been the standard materials for achieving well engineered insulation
            characteristics in reference to heavy electrical usage</>,
            
            subtitle2:"APPLICATIONS",
            paragraph2:<>Medium and high voltage industry. Besides the classical medium voltage
            from 12 to 36 kV and high voltage up 1000 kV other industries, like the
            automotive, high-speed train and motor stator applications show a rapid
            growth and acceptance in the market. The advantage lies in the fact that
            that in many cases, components can be built smaller, compared to conventional insulation technologies.</>,
        },       
    about_us : 
        {
            title:"We are Manufacturers  Of Various Types Of Epoxy Moulded Components",
            title_paragraph: <>Such As Support Insulators, Bushings, Spout Bushings, Breaker Bushings, 
            Tap Changer Components,
            RMU Bushings and Various Types Of Epoxy Parts.</>,

            sub_title : "We believe in being resourceful",
            sub_title_paragraph :<>We have well experienced working team in Production, Development,
             Testing & Marketing having
            experience of more than
            18 years .The team is extremely dedicated and passionate about their work</>,

            card_title1 : "Our Mission" ,
            card_paragraph1 : <>" To become first choice of customers who believes in Quality.."</>,

            card_title2 : "Our Vision" ,
            card_paragraph2 : <>Zero defect , Satisfactory Customer with repeat orders , Customer feedback is 
            carefully studied and worked upon ,as it provides us a strong basis for future upgrades. <br />
            We follow a constant improvement program to maintain the high quality and reliability of our products.</>,
             meta : {
                title: 'Sunshine Engineering | About Us',
                keywords: 'Various Types Of Epoxy Parts, RMU Bushings,Insulators, Bushings, Spout Bushings,Tap Changer Components, Breaker Bushings,experienced working team in Development,experienced working team in Testing & Marketing,our mission,our vision, dedicted team, Satisfactory Customer,believes in Quality,experienced working team in Production',
                description: 'Zero defect , Satisfactory Customer with repeat orders , Customer feedback is  carefully studied and worked upon ,as it provides us a strong basis for future upgrades. " To become first choice of customers who believes in Quality.."'
            }
        },
    footer : 
    {
            quote : <>We are manufacturers of various types of Epoxy Bushings & Insulators and also manufacture
             various types Of Epoxy Molded Components such as Support Insulators, Bushings, Spout Bushings, 
             Breaker Bushings, Tap Changer Components, RMU Bushings and Various Types Of Epoxy Parts.</>,
            adress1 : <>E6 Chaitraban Residency ,ITI Road,Sarja Hotel Lane, Aundh Pune-411007</>,
            adress2:<>At Village Gorthan Khurd, Post -Donwat,Tal- Khalapur,
            Dist- Raigad,Pin- 410203</>,
            gstin:"GSTN: 27AEQFS8996D1Z3",
            email1:"sales@sunshineengineering.co",
            email2:"sunshineengineering23@gmail.com",
            mobile_no1 : "+91 9356202821",
            mobile_no2 : "+91 7499544230",
            link : "www.sunshineengineering.co",
    },
    contact :
    {
            meta : {
                title: 'Sunshine Engineering | Contact us',
                keywords: 'Good customer care, pune epoxy bushing and insulator manufacturing unit',
                description: 'We have very supportive customer care'
            }
    }
}

export default data;
