import React from 'react';
import './App.css';
import MyNav from './components/MyNav';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Facility from './components/Facility';
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollTop';
import ProductTile from './components/ProductTile';
import Product from './components/Product';
import FloatToTop from './components/FloatToTop';

function App() {
  return (
    <div style={{width:"100%"}} className="mt-0">
     <BrowserRouter>
     <ScrollToTop/>
      <MyNav/>
        <Routes>
          <Route exact path="/" element={<Home/> }>
          </Route>
          <Route path="/about" element={<AboutUs/> }>
          </Route>
          <Route path="/facility"  element={<Facility/> }>
          </Route>
          <Route path="/contact" element={<Contact/> }> 
          </Route>
          <Route path="/bush/:id" element={<Product />}>
          </Route>
          <Route path="/insulator/:id" element={<Product/>}>
          </Route>
          <Route path="/products" element={<ProductTile />}>
          </Route>
        </Routes> 
        <FloatToTop/>    
        <Footer/>

      </BrowserRouter>
    </div>
  );
}

export default App;
