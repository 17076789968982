import React from 'react'
import ProductCard from './ProductCard';
import data from '../data/data';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Product() {
  let { id } = useParams();
  return (
    <div  data-aos="fade-down">
       <Helmet>
              <title>{data.products.meta.title}</title>
              <meta name="keywords" content={data.products.meta.keywords} />
              <meta name="description" content={data.products.meta.description} />
        </Helmet>
      { 
        data.product_docs.map((product) =>
          product.id === id  &&
            <>
            <ProductCard title={product.title} id={product.id} pdf_file={product.doc} />
            </>
          
        )
      }
      </div>
  )
}

export default Product
