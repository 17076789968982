import React, { Component } from 'react';
//  import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../data/images/logo.webp';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from "react-router-dom";
import data from '../data/data';


class MyNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        "width": "0%"
      },
      expanded: false
    };
    this.OpenNav = this.OpenNav.bind(this);
    this.CloseNav = this.CloseNav.bind(this);
  }
  OpenNav = (style = this.state.style) => {
    style = { "width": "100%" };
    this.setState({ style }, () => console.log('OpenNav Set: ', this.state.style));
    // console.log('Get: ', this.state.style);
  }
  CloseNav = (style = this.state.style) => {
    style = { "width": "0%" };
    this.setState({ style }, () => console.log('CloseNav Set: ', this.state.style));
    // console.log('Get: ', this.state.style);
    this.CollapseNavbar();
  }
  ExpandNavbar = (expanded = this.state.expanded) => {
    // console.log('Get: ', this.state.expanded);
    expanded = true;
    this.setState({ expanded }, () => console.log('SetExpanded Set: ', this.state.expanded));
    // console.log('Get: ', this.state.expanded);
  }
  CollapseNavbar = (expanded = this.state.expanded) => {
    // console.log('Get: ', this.state.expanded);
    expanded = false;
    this.setState({ expanded }, () => console.log('SetExpanded Set: ', this.state.expanded));
    // console.log('Get: ', this.state.expanded);
  }
  
  render() {
    return (
      <>
        <Navbar className='nav-content-item ' collapseOnSelect expand="lg" expanded={this.state.expanded} variant="light" sticky='top'>
          <>
            <NavLink className="logo-height" to="/"><img className='logo' src={Logo} alt="logoimage" /></NavLink>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.state.expanded ? this.CollapseNavbar : this.ExpandNavbar} />
            <Navbar.Collapse className='navbar-collapse-item collapse-nav' id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavLink className='navlink-items nav-item-hover' to="/" onClick={this.CloseNav}>
                  HOME
                </NavLink>
                <NavLink className='navlink-items nav-item-hover' to="/about" onClick={this.CloseNav}>
                  ABOUT US
                </NavLink>
                <NavDropdown className='navlink-items nav-item-hover dropdown-color' style={{ paddingTop: "8px", cursor: "pointer" }} title="PRODUCTS" id="collasible-nav-dropdown" onClick={this.OpenNav}>
                  <div id="aboutNav" className='row dropdown-btn collapse-dropdown-menu nav-dropitem-align'>
                    <div className='col-lg-4'>
                      <h6>
                        EPOXY INSULATORS
                      </h6>
                        {
                          data.product_docs.map((product, index) =>
                          (
                            (product.type === 'insulator') &&
                            <NavDropdown.Item className='dropitem nav-dropitem-align' as={Link}  to={`/insulator/${product.id}`} onClick={this.CloseNav} comp>
                              {product.title}
                              <br/>
                            </NavDropdown.Item>
                          )
                          )
                        }
                    </div>
                    <div className='col-lg-4'>
                      <h6>
                        EPOXY BUSHINGS
                      </h6>
                        {
                          data.product_docs.map((product, index) =>
                          (
                            (
                              product.type === 'bush') &&
                            <NavDropdown.Item className='dropitem nav-dropitem-align' as={Link}  to={`/bush/${product.id}`} onClick={this.CloseNav} comp>
                              {product.title}
                              <br/>
                            </NavDropdown.Item>
                          )
                          )
                        }
                    </div>
                  </div>
                </NavDropdown>
                <NavLink className='navlink-items nav-item-hover' to="/facility" onClick={this.CloseNav}>
                  FACILITY
                </NavLink>
                <NavLink className='navlink-items nav-item-hover' to="/contact" onClick={this.CloseNav}>
                  CONTACT US
                </NavLink>
              </Nav>
              <Nav className='right-nav tab-nav'>
                <span className='navlink right-nav-link' style={{ marginRight: "20px" }}>
                  <a href="https://api.whatsapp.com/send?phone=+91 9356202821&text=This%20is%20test%20Message" className='footer-link '>
                    <i class="fa-brands fa-whatsapp right-nav-link nav-icon"></i>
                  </a>
                    +91 9356202821<br />
                  <i className="fa-solid fa-envelope nav-icon" ></i>
                  <Link className='nav-media-link' to="#"
                    onClick={(e) => {
                      window.location.href = "mailto:sales@sunshineengineering.co";
                      e.preventDefault();
                    }}>
                      {data.footer.email1}
                  </Link>
                </span>
                <span className='navlink right-nav-link' >
                  <i className="fa-solid fa-mobile-screen nav-icon"></i>
                    +91 7499544230
                  <br />
                  <i className="fa-solid fa-envelope nav-icon"></i>
                  <Link className='nav-media-link' to="#"
                    onClick={(e) => {
                      window.location.href = "mailto:sunshineengineering23@gmail.com";
                      e.preventDefault();
                    }}>
                      {data.footer.email2}
                  </Link>
                  <br />
                </span>
              </Nav>
            </Navbar.Collapse>
          </>
        </Navbar>
      </>
    )
  }
}
export default MyNav;
