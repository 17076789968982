import React from 'react';
import AboutCard from './About-Card';
import data from '../data/data';
import { Helmet } from 'react-helmet';

function AboutUs() {

    return (
        <> 
            <div className='container' >
                <Helmet>
                    <title>{data.about_us.meta.title}</title>
                    <meta name="keywords" content={data.about_us.meta.keywords} />
                    <meta name="description" content={data.about_us.meta.description} />
                </Helmet>
                <div className='row' style={{ zIndex: "-1" }}  >
                    <div className='col-lg-6' data-aos="fade-down">
                        <h1 className='mt-5'>
                            {data.about_us.title}
                        </h1>
                        <br />
                        <br />
                        <p className='aboutus-p '>
                            {data.about_us.title_paragraph}
                        </p>
                    </div>
                    <div className='col-lg-6'  data-aos="fade-down">
                        <img src={data.media["3"].image} style={{ width: "100%" }} alt='AboutImage' />
                    </div>
                </div>
                <div className='row mb-5 mt-5 image-align-center card-row'  data-aos="fade-down">
                    {data.media.map(img =>
                    (img.type === 'tile' &&
                        <div className='col-md-6 col-lg-4 col-12 card-align-2-row' >
                            <AboutCard image={img.image} title={img.name} />
                            <br/>
                        </div>
                    )
                    )}
                </div>
                <div data-aos="fade-down">
                    <h2 style={{ color: "Grey" }}>
                        {data.about_us.sub_title}
                    </h2>
                    <br />
                    <p className='aboutus-p'>
                        {data.about_us.sub_title_paragraph}
                    </p>
                </div>
                <br/>
                <div className='row' style={{padding:"15px"}}  >
                    <div className='col-lg-5 aboutus-col' data-aos="fade-down">
                        <h2 >{data.about_us.card_title1}</h2>
                        <p className='aboutus-p'>
                            {data.about_us.card_paragraph1}
                        </p>
                    </div>
                    <div className='col-lg-2' data-aos="fade-down"></div>
                    <div className='col-lg-5 aboutus-col' data-aos="fade-down">
                        <h2>
                            {data.about_us.card_title2}
                        </h2>
                        <p className='aboutus-p'>
                            {data.about_us.card_paragraph2}
                        </p>
                    </div>
                </div>
            </div>
        </>)
}
export default AboutUs
