import React from 'react';
import Card from 'react-bootstrap/Card';

function AboutCard(props) {
  return (
    <>
      <Card className='about-us-card'>
        <Card.Img variant="top" src={props.image} />
        <Card.Body>
          <Card.Title><h3>{props.title}</h3></Card.Title>
        </Card.Body>
      </Card>                
    </>
  )
}

export default AboutCard
