import React from 'react';
import '../App.css';
import Carousel from 'react-bootstrap/Carousel';
import ProductTile from './ProductTile';
import data from '../data/data';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import {Helmet} from "react-helmet";

function Home() {
  return (
    <>
     <Helmet>
        <title>{data.home.meta.title}</title>
        <meta name="keywords" content={data.home.meta.keywords} />
        <meta name="description" content={data.home.meta.description} />
        </Helmet>

      <Carousel>
        {
          data.media.map(item =>
            item.type === 'caraousel' &&
            <Carousel.Item>
              <img
                className="d-block w-100 carousel-heigth" 
                src={item.image}
                alt={item.name}
              />
              <Carousel.Caption>
                <h1 style={{fontSize:"6vw",fontFamily:"Lucida Console"}}><i>{ item.title } </i></h1>
                <h5 style={{fontSize:"2.5vw",color:"white"}}>{ item.subtitle } </h5>
              </Carousel.Caption>
            </Carousel.Item>
            )
        }
      </Carousel>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-6' data-aos="fade-down">
            <h1  style={{textAlign: "center" }}>
              <b>
                { data.home.title }
              </b>
            </h1>
            <br />
            <p className=' para-align home-descroption'>
              {data.home.description}
            </p>
          </div>
          <div className='col-lg-6' data-aos="fade-down">
            <img src={data.home.image} alt="sunshine" className='image-align-center mb-5 ' style={{width:"100%"}} />
            {/* <img src={data.home.subImage1} alt="sunshine" className='image-align-center mb-5 ' style={{width:"100%"}} /> */}
            <img src={data.home.subImage2} alt="homeimage" className='image-align-center mt-5' style={{width:"100%"}}/>
          </div>
        </div>
      </div>
      <h3 className='home-quote' data-aos="fade-down"> 
        { data.home.quote }
      </h3>
      <div className='row' data-aos="fade-down" style={{margin:"auto", float:"none",alignItems:"center"}}>
          <ProductTile />
      </div>
      <div className='container butto_center'>
        <Button className='contact-button'>
          <Link className='home-link'  to="/products">View More...</Link>
        </Button>
      </div>
    </>
    )
}

export default Home
