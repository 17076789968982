import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ContactImage from '../data/images/contact-image.jpg';
import { Helmet } from 'react-helmet';
import data  from '../data/data';

function Contact() {
  const [name,setName]= useState('')
  const [email,setEmail]=useState('')
  const [phone, setPhone]=useState('')
  const [description,setDescription]=useState('')

  const handleSubmit = (e) =>{
        e.preventDefault();
        if(name === ""){
          alert('Name is Required');
          return;
        }
        else if(email === ""){
          alert('Email is required');
          return;
        }
        else if(phone === ""){
          alert('Phone number is required');
          return;
        }
        else if(description === ""){
          alert('Description is required');
          return;
        }
        // console.log(name,email,phone,description)       
            const data ={
              Name:name,
              Email_id : email,
              Phone_no : phone,
              Description : description
            }

          fetch('https://sheet.best/api/sheets/ce2f471c-05b4-4035-ac4f-a40a83378a6a',{
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
               'Content-type': 'application/json; charset=UTF-8',
               'Authorization' : '5bqMDSZnNJbcM#JyMzXEDwk2_uduV!6AwJPx163G#p94G30cgqMZqKjytzb%6qT$'
            },
          })
          .then((response) => response.json())
          .then((Newdata) => {
            alert('Thanks for contacting us... ')
            setEmail('');
            setName('');
            setDescription('');
            setPhone('');
      })
      .catch((err) => {
         console.log(err.message);
         alert('Something went wrong')
      });
}
  return (
    <div className='container'>
       <Helmet>
        <title>{data.contact.meta.title}</title>
        <meta name="keywords" content={data.contact.meta.keywords} />
        <meta name="description" content={data.contact.meta.description} />
        </Helmet>
      <div className='row mb-5 mt-5' style={{ zIndex: "-1" }}>
        <div className='col-lg-6 mb-5 ' data-aos="fade-up">
          <h1  style={{ textAlign: "center"}}>
            Get in touch
          </h1>
          <img  src={ContactImage} alt="contactIamge" style={{ width: "100%"}} />
        </div>
        <div className='col-lg-6' data-aos="fade-down">
          <h1  style={{ textAlign: "center" }}>
            Talk with us
          </h1>
          <Form autoComplete='off'>           
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e) =>setName(e.target.value)} required />
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) =>setEmail(e.target.value)} required/>
              <Form.Label>Phone No</Form.Label>
              <Form.Control type="text" placeholder="Enter Phone number" value={phone} onChange={(e) =>setPhone(e.target.value)} required/>
              <Form.Label>Description</Form.Label >
              <Form.Control as="textarea" rows={5} value={description} onChange={(e) =>setDescription(e.target.value)} required />
              <br />
              <Button className='contact-button' type="submit" onClick={handleSubmit}>
                  Submit
              </Button>{" "} {" "}
              {/* <Button className='contact-button' type="submit">
                  Cancel
              </Button> */}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Contact
